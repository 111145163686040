<template>
    <div>
        <label v-if="label" :for="id || name">{{ label }}</label>
        <input :type="type"
               class="form-control"
               :class="{'is-invalid' : !isValid, [className]: className}"
               :id="id || name"
               :name="name"
               :min="min"
               :max="max"
               :max-length="minLength"
               :min-length="minLength"
               :placeholder="placeholder"
               v-model="includedValue"
               v-on="$listeners"
               :disabled="disabled"
               :required="required"
               :readonly="readonly"
               :autocomplete="autocomplete"
               />
        <div v-if="!isValid && typeof v === 'string' && showError" class="invalid-feedback text-nowrap">{{ v }}</div>
        <div v-else-if="!isValid && !v.required && typeof v.required !== 'undefined' && showError" class="invalid-feedback text-nowrap">The field is required.</div>
        <div v-else-if="!isValid && !v.alphaNum && typeof v.alphaNum !== 'undefined' && showError" class="invalid-feedback text-nowrap">Only alphanumeric characters are allowed.</div>
        <div v-else-if="!isValid && !v.alpha && typeof v.alpha !== 'undefined' && showError" class="invalid-feedback text-nowrap">Only alphabetic characters are allowed.</div>
        <div v-else-if="!isValid && !v.email && typeof v.email !== 'undefined' && showError" class="invalid-feedback text-nowrap">The content of this field is not e-mail.</div>
        <div v-else-if="!isValid && !v.minLength && typeof v.minLength !== 'undefined' && showError" class="invalid-feedback text-nowrap">The content of this field must have at least {{v.$params.minLength.min}} letters.</div>
        <div v-else-if="!isValid && !v.sameAs && typeof v.sameAs !== 'undefined' && showError" class="invalid-feedback text-nowrap">This field must be the same like '{{v.$params.sameAs.eq}}' field.</div>
        <div v-else-if="!isValid && !v.notSameAs && typeof v.notSameAs !== 'undefined' && showError" class="invalid-feedback text-nowrap">This field must be different from the '{{v.$params.notSameAs.notEqualTo}}' field.</div>
    </div>
</template>

<script>
export default {
    name: "Input",
    data: () => ({
        includedValue: null,
    }),
    mounted() {
        this.includedValue = this.value;
    },
    props: {
        type: {
            type: String,
            default: 'text',
        },
        name: {
            type: String,
            required: true,
        },
        value: {
            type: [String,Number],
            default: '',
        },
        label: String,
        id: String,
        className: String,
        placeholder: String,
        min: Number,
        max: Number,
        maxLength: Number,
        minLength: Number,
        disabled: Boolean,
        required: Boolean,
        readonly: Boolean,
        autocomplete: {
            type: String,
            validator: (value) => ['on', 'off','current-password','new-password','username', 'organization'].indexOf(value) !== -1,
        },
        showError: {
            type: Boolean,
            default: true,
        },
        v: [Object,String,undefined,null],
    },
    computed: {
        isValid() {
            if([null,undefined].indexOf(this.v) > -1) return true;
            return typeof this.v === 'string' ? false : !this.v.$error
        }
    },
    watch: {
        value(value){
            this.includedValue = value;
        },
        includedValue(value){
            const {name} = this;
            // if(typeof this.v === 'string') this.v = null;
            this.$emit('change',{name,value});
        },
    }
}
</script>
<style lang="scss">
.form-control-text{
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
</style>