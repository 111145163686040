<template>
    <p class="lead text-primary d-lg-flex align-items-center text-center">
        We are <strong class="font-weight-medium ml-2">certified with regard to</strong>

        <span class="d-flex d-inline-lg-flex flex-wrap justify-content-center align-items-center mt-4 mt-lg-0">
            <img id="iso" class="img-fluid ml-0 ml-lg-4 ml-xl-6" src="@/assets/img/certificates/iso.png">
            <img id="gdp" class="img-fluid" src="@/assets/img/certificates/gdp.png">
            <img id="tapa" class="img-fluid" src="@/assets/img/certificates/tapa.png">
            <img id="ifs" class="img-fluid" src="@/assets/img/certificates/ifs.png">
            <img id="iata" class="img-fluid" src="@/assets/img/certificates/iata.png">
        </span>
    </p>
</template>

<script>

export default {
    name: "CertifiedLogos",
}
</script>

<style scoped lang="scss">
img {
    margin-left: 2rem;
    opacity: 0.7;
    @include media-breakpoint-between('lg','xl'){
        margin-left: 1.5rem;
    }
    @include media-breakpoint-down('xs'){
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 1rem;
    }
}

#iso {
    width: 76px;
}

#gdp {
    width: 44px;
}

#tapa {
    width: 84px;
}

#ifs {
    width: 64px;
}

#iata {
    width: 60px;
}
</style>